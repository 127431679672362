// ** Next Imports
import Link from "next/link";

// ** File Imports
import Layout from "../layouts/default";

// ** FA Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrafficLight } from "@fortawesome/free-solid-svg-icons";

const NotFound = () => (
  <Layout>
    <div className="error_404">
      <FontAwesomeIcon icon={faTrafficLight} />
      <h2>404</h2>
      <h5>SORRY - PAGE NOT FOUND!</h5>
      <p>The page you are looking for was moved, removed, or does not exist.</p>
      <h6>
        <Link href="/">Go To HomePage</Link>
      </h6>
    </div>
  </Layout>
);

export default NotFound;
